import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './index.css'
import Modal from '../modal/index';
import Form from '../Form/index';

export default function BasicCard() {

  

  
  return (
    <Card >
     <div><Form></Form></div>
    </Card>
  );
}

// <CardContent>
//         <h1 className='Header'>Payment Form</h1>

//         <div className='FormBody'>
//             <form>

//             <label>First name:</label> <input></input> <br></br>
//             <label>Last name:</label> <input></input> <br></br>
//             <label>Case Number:</label> <input></input> <br></br>
//             <label>Amount Due:</label> <input type="number"></input> <br></br>

  
//             </form>
           
//         </div>
//       </CardContent>
//       <Modal amount = {'10'}></Modal>