import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '../card/index';
import { margin } from '@mui/system';



export default function BasicGrid() {
  return (
    <Box sx={{ flexGrow: 1 ,margin: 10 }}>
      <Grid container spacing={1}
  direction="column"
  justifyContent="center"
  alignItems="center">
        <Card></Card>
        


      </Grid>
      <Grid> <div className="Anon-logo">

<img src="https://upload.wikimedia.org/wikipedia/commons/a/a6/Anonymous_emblem.svg" width="150px" ></img>

<h2>
    We're Anonymous, we don't foget, we don't forgive, expect us.
</h2>
</div></Grid>
    </Box>
  );
}
