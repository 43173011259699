import logo from './logo.svg';
import './App.css';
import Grid from './components/grid/index';
import Navbar from './components/navbar/index';
import Form from './components/Form/index';
import Card from './components/card/index';

function App() {
  return (
    <div className="App">
    
    <ul><h1>Anonymous</h1></ul>
     
        <Grid></Grid> 


    </div>
  );
}

export default App;
