import Modal from '../modal/index'
import '../Form/index.css';

function Form() {
    return (
        <div className="Container">

            <div className="Form">
                <h3>Please Fill The Payment Form Below</h3>

                <form>
                    <label>
                        <p1>First Name</p1>
                        <input type="text" name="name" /> <br></br>
                    </label>


                    <label>
                        <p1>Last Name</p1>
                        <input type="text" name="name" />
                        <br></br>
                    </label>


                    <label>
                        Case Number:
                        <input type="text" name="name" />
                        <br></br>
                    </label>

                    <label>
                        Amount Due (BTC):
                        <input type="text" name="name" />
                    </label>
                    <br></br>


                    
                   
                   <div>
                   <Modal></Modal>
                   </div>
                    


                </form>

            </div>




           



        </div>


    )
}

export default Form;